import React from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { TABLET_WIDTH } from "../../../constants";
import GPBModal from "../GPBModal";

const BrightcoveVideoModal = ({ isOpen, onRequestClose, accountId, playerId, videoId, triggerElement }) => {
  const { width: screenWidth } = useWindowDimensions();

  let url = `https://players.brightcove.net/${accountId}/${playerId}_default/index.html?videoId=${videoId}`;
  let iframeWid = screenWidth < TABLET_WIDTH ? "320px" : "580px";
  let iframeHeight = screenWidth < TABLET_WIDTH ? "200px" : "328px";

  return (
    <GPBModal isOpen={isOpen} onRequestClose={onRequestClose} triggerElement={triggerElement}>
      <iframe src={url} allowFullScreen={true} allow="encrypted-media" height={iframeHeight} width={iframeWid}></iframe>
    </GPBModal>
  );
};

export default BrightcoveVideoModal;
