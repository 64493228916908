import GameAgeCategoryTag from "./GameAgeCategoryTag";
import categoryData from "../../../data/categories.json";

const BORDER_COLORS = ["#15A9D7", "#DB29E2", "#FF7602", "#071F6F", "#15A683"];

const GameBlock = ({ game }) => {
  const gameAgesData = categoryData.ages.find((catDataAge) => catDataAge.id == game.categories?.ageId);
  // If we want to include category Subjects in alt text later
  // const gameCategoryNamesString = categoryData.subjects
  //   .filter((catDataSubj) => game.categories?.subject?.includes(catDataSubj.id))
  //   ?.map((subj) => subj.name)
  //   ?.join(", ");

  if (gameAgesData == undefined) console.log("Couldn't find matching category data for age ids: " + game.categories?.ageId);

  // Pick a random border color
  const randomIntFromInterval = (min, max) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const rdmIdx = randomIntFromInterval(0, BORDER_COLORS.length - 1);
  const borderColor = BORDER_COLORS[rdmIdx];

  const onGameClick = () => {
    window.open(game.linkUrl, "_blank");
  };

  const altText = `Title: ${game.title}. Description: ${game.description} Category tags: Age ${gameAgesData.minimumAge}+`;

  return (
    <div className="flex flex-col w-[280px] md:w-[380px]">
      <div className="rounded-3xl border-2 border-b-8 w-full h-[210px] md:h-[360px] relative overflow-hidden focus-within:ring-1 focus-within:ring-blue-500 focus-within:ring-offset-4" style={{ borderColor: borderColor }}>
        <div
          className="relative border-4 border-white rounded-3xl h-full overflow-hidden z-40 hover:cursor-pointer focus:ouline-none focus-visible:outline-none"
          onClick={onGameClick}
          tabIndex="0"
          alttext={altText}
          title={altText}
          aria-label={altText}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              onGameClick();
            }
          }}
        >
          {/* Game Name top left banner */}
          <div className="absolute bg-white rounded-br-2xl font-bold text-[#3057DD] py-1 md:py-1 pl-1 md:pl-3 w-[2/3] z-30 shadow-medium pointer-events-none">
            <div className="ml-1 pl-1 pr-8 pb-1 font-fredokaSB text-base md:text-2xl">{game.title}</div>
          </div>
          {/* Background image / GIF */}
          <div className="absolute w-full top-0 left-0 h-full">
            <div className="absolute top-0 left-0  w-full h-full z-20 hover:opacity-0">
              <div className="h-[140px] md:h-[270px]">{game.images.default && <img className="object-cover h-full w-full" src={game.images.default} alt={""} />}</div>
            </div>
            <div className="absolute top-0 left-0 w-full h-[140px] md:h-[270px] z-10">{game.images.animated && <img className="object-cover h-full w-full" src={game.images.animated} alt="" />}</div>
          </div>
          {/* Description footer */}
          <div className="absolute bottom-0 left-0 w-full h-[55px] md:h-[75px] bg-[#071F6F]">
            {/* Game Age Bottom Right Banner */}
            <div className="absolute bottom-0 right-0 w-[65px] md:w-1/4 rounded-tl-2xl text-center md:text-base text-xs py-1 text-white bg-[#FF7602]">Age {gameAgesData.minimumAge}+</div>
            <div className="relative text-white font-bold text-left text-[.6rem] md:text-[.85rem] w-3/4 pl-3 pr-1 py-2 font-nunitoB leading-3 md:leading-[1.1rem]">{game.description}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameBlock;
