import FiltersRow from "./ui/all-games/FiltersRow";
import categoryData from "../data/categories.json";

const AllGamesFilters = ({ onFilterChange, disabledCategories, selectedAge, selectedSubject }) => {
  const onChildFilterChange = (categoryName, filterId) => {
    onFilterChange(categoryName, filterId);
  };

  return (
    <div className="flex flex-col gap-1 md:gap-2 py-4">
      <FiltersRow categoryName={"ages"} categoriesList={categoryData.ages} themeColor="#FF7602" filtersUpdateHandler={onChildFilterChange} disabledCategories={disabledCategories} selectedId={selectedAge} />
      <FiltersRow categoryName={"subjects"} categoriesList={categoryData.subjects} themeColor="#3057DD" filtersUpdateHandler={onChildFilterChange} disabledCategories={disabledCategories} selectedId={selectedSubject} />
    </div>
  );
};

export default AllGamesFilters;
