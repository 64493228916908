import { useEffect, useState } from "react";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <div>
          <button onClick={scrollToTop} className="px-12 py-3 text-white font-bold bg-[#13A9D6] rounded-3xl items-center font-nunitoEB text-lg md:text-xl drop-shadow-xl hover:brightness-75 whitespace-nowrap">
            <i className="fa-solid fa-caret-up fa-2xl pr-2"></i>
            Return to Top
          </button>
        </div>
      )}
    </div>
  );
};

export default ScrollToTop;
