const ScrollButton = ({ onClick, isLeft }) => {
  return (
    <div>
      <button onClick={onClick} className="rounded-full text-white" style={{ opacity: 1, backgroundColor: "transparent" }} title={isLeft ? "Scroll left" : "Scroll right"}>
        {isLeft && <i className="fa-solid fa-caret-left text-2xl" />}
        {!isLeft && <i className="fa-solid fa-caret-right text-2xl" />}
      </button>
    </div>
  );
};

export default ScrollButton;
