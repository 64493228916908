import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

const GPBModal = ({ isOpen, children, onRequestClose, triggerElement }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const firstInteractiveElement = useRef(null); // Reference to the first interactive element in the modal

  const handleEscapeKey = (e) => {
    if (e.keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    setIsBrowser(true);

    if (isOpen) {
      document.addEventListener("keydown", handleEscapeKey);
      document.body.style.overflow = "hidden"; // Disable scrolling
      // Delay focus to ensure elements are rendered
      setTimeout(() => firstInteractiveElement.current?.focus(), 0);
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      if (!isOpen) {
        document.body.style.overflow = "unset"; // Enable scrolling
      }
    };
  }, [isOpen, onRequestClose]);

  const handleOverlayClick = () => {
    closeModal();
  };

  const closeModal = () => {
    document.removeEventListener("keydown", handleEscapeKey);
    document.body.style.overflow = "unset"; // Enable scrolling
    triggerElement.current?.focus(); // Restore focus to the element that triggered the modal
    onRequestClose();
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const modalContent = isOpen ? (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={handleOverlayClick}>
      <div className="relative bg-white p-6 mx-auto rounded shadow-lg" onClick={handleModalClick}>
        <div className="absolute top-1 rounded-sm right-2 p-2 flex h-3 w-3 text-gray-500 hover:text-black hover:bg-gray-200 ml-auto items-center justify-center">
          <button ref={firstInteractiveElement} className="md:text-lg" aria-label="Close" onClick={handleOverlayClick}>
            x
          </button>
        </div>
        <div>{children}</div>
      </div>
    </div>
  ) : null;

  if (isBrowser) {
    return ReactDOM.createPortal(modalContent, document.getElementById("modal-root"));
  } else {
    return null;
  }
};

export default GPBModal;
