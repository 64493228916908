import { useEffect, useRef, useState } from "react";
import FilterAllButton from "./FilterAllButton";
import FilterButton from "./FilterButton";
import ScrollButton from "../ScrollButton";

const FiltersRow = ({ categoryName, categoriesList, themeColor, filtersUpdateHandler, disabledCategories, selectedId }) => {
  // State to track whether the buttons container is overflowed
  const [isOverflowRight, setIsOverflowRight] = useState(false);
  const [isOverflowLeft, setIsOverflowLeft] = useState(false);

  // Refs for the buttons container and the content
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const checkOverflow = () => {
    if (containerRef.current && contentRef.current) {
      setIsOverflowRight(containerRef.current.clientWidth < contentRef.current.scrollWidth);
      setIsOverflowLeft(containerRef.current.scrollLeft > 0);
    }
  };

  useEffect(() => {
    // Check overflow on mount
    checkOverflow();

    // Check overflow on window resize
    window.addEventListener("resize", checkOverflow);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const scrollLeft = () => {
    if (containerRef.current && contentRef.current) {
      containerRef.current.scrollLeft -= 100;
      checkOverflow();
    }
  };

  const scrollRight = () => {
    if (containerRef.current && contentRef.current) {
      containerRef.current.scrollLeft += 100;
      checkOverflow();
    }
  };

  const filterClickHandler = (categoryName, key) => {
    filtersUpdateHandler(categoryName, key);
  };

  return (
    <div className="relative flex items-center px-1">
      {/* Scroll Left */}
      {isOverflowLeft && (
        <div className="absolute -left-3 bottom-1 md:bottom-5">
          <ScrollButton onClick={scrollLeft} isLeft={true} />
        </div>
      )}

      {/* Button Content */}
      <div ref={containerRef} className="flex gap-3 md:gap-8 overflow-x-auto scroll-smooth scroll-mx-3 md:scroll-px-4 py-1">
        <div ref={contentRef} className="flex space-x-4">
          <FilterAllButton title={categoryName} bgColor={themeColor} onClickHandler={() => filterClickHandler(categoryName, -1)} isSelected={selectedId == -1} />
          <div className="flex gap-2 md:gap-4">
            {categoriesList &&
              categoriesList.map((cat) => {
                return (
                  <FilterButton
                    key={cat.id}
                    name={categoryName == "ages" ? "Age " + cat.minimumAge.toFixed() + "+" : cat.name}
                    themeColor={themeColor}
                    onClickHandler={() => filterClickHandler(categoryName, cat.id)}
                    disableOverride={disabledCategories.includes(cat.id)}
                    isSelected={selectedId == cat.id}
                  />
                );
              })}
          </div>
        </div>
      </div>

      {/* Scroll Right */}
      {isOverflowRight && (
        <div className="absolute -right-3 bottom-1 md:bottom-5">
          <ScrollButton onClick={scrollRight} isLeft={false} />
        </div>
      )}
    </div>
  );
};

export default FiltersRow;
