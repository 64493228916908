import React from "react";

const GPBPlayButton = React.forwardRef(({ onClick, altText }, ref) => {
  return (
    <div>
      <button ref={ref} onClick={onClick} title={altText} className="rounded-full bg-white border-4 border-[#9CC0F7] w-[60px] h-[60px] group-hover:brightness-75">
        <i className="fas fa-caret-right text-4xl text-[#2A78DF]" />
      </button>
    </div>
  );
});

export default GPBPlayButton;
