const FilterButton = ({ name, themeColor, onClickHandler, disableOverride = false, isSelected = false }) => {
  return (
    <button
      className="font-bold text-center bg-white rounded-lg text-sm px-2 whitespace-nowrap md:text-base md:px-4 py-[.25rem] transition-colors duration-500 font-nunitoEB hover:brightness-75"
      style={{ color: isSelected ? "white" : themeColor, backgroundColor: isSelected ? themeColor : "white", opacity: disableOverride ? ".6" : "1", cursor: disableOverride ? "not-allowed" : "default" }}
      onClick={onClickHandler}
      disabled={disableOverride}
    >
      {name}
    </button>
  );
};

export default FilterButton;
