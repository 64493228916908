const NavLink = ({ textName, urlLink }) => {
  return (
    <a
      href={urlLink}
      className="font-bold text-white text-xs md:text-xl font-nunitoB hover:cursor-pointer whitespace-nowrap hover:underline-offset-2 hover:underline"
      target="_blank"
      onKeyDown={(e) => {
        // cover space bar for click
        if (e.key === " ") {
          e.preventDefault();
          window.open(urlLink, "_blank");
        }
      }}
    >
      {textName}
    </a>
  );
};

export default NavLink;
