import VideoBlock from "./VideoBlock";

const VideosList = ({ videos }) => {
  const sortedVideos = videos.sort((vid1, vid2) => vid1.order - vid2.order);

  return (
    <div className="w-full bg-white flex justify-center">
      <div className="w-fit grid grid-cols-1 gridLarge:grid-cols-2 md:grid-flow-row gap-6 pt-8 pb-12 justify-items-center bg-white px-4">
        {sortedVideos &&
          sortedVideos.map((vid, idx) => {
            return <VideoBlock key={idx} videoData={vid} />;
          })}
      </div>
    </div>
  );
};

export default VideosList;
