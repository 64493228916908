import { ReactComponent as PrintableLogo } from "../icons/icon_printables.svg";

const Printables = () => {
  return (
    <div className="flex py-2 md:py-2 font-bold px-6 md:px-7 bg-[#008181] shadow-gpbButton text-white text-left rounded-xl w-fit items-center gap-2 hover:brightness-75">
      <PrintableLogo className="w-[15px] h-[15px]" />
      <button className="font-nunitoEB md:text-base text-sm" onClick={() => window.open("https://www.gpb.org/education/games/printables", "_blank")}>
        Printables
      </button>
    </div>
  );
};

export default Printables;
