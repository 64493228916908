const FilterAllButton = ({ title, bgColor, onClickHandler, isSelected }) => {
  return (
    <button
      className="flex justify-between px-2 whitespace-nowrap hover:brightness-75 w-[130px] md:w-[180px] items-center rounded-lg md:text-base text-sm font-nunitoEB"
      style={{ backgroundColor: isSelected ? bgColor : "white", color: isSelected ? "white" : bgColor, cursor: isSelected ? "not-allowed" : "default" }}
      onClick={onClickHandler}
      disabled={isSelected}
      tabIndex={0}
    >
      <div className="font-bold ">All {title.charAt(0).toUpperCase() + title.slice(1)}</div>
    </button>
  );
};

export default FilterAllButton;
