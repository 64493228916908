import React, { useRef, useState } from "react";
import BrightcoveVideoModal from "./BrightcoveVideoModal";
import GPBPlayButton from "../GPBPlayButton";

const VideoBlock = ({ videoData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const playButtonRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const altText = `Title: ${videoData.title}, Description: ${videoData.description}`;

  return (
    <div className="rounded-3xl border-2 border-b-8 border-[#04A1FF] overflow-hidden ">
      <div className="border-4 border-white rounded-3xl md:w-[580px] h-[275px] sm:h-[300px] md:h-[375px] overflow-hidden relative group hover:cursor-pointer">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <GPBPlayButton ref={playButtonRef} onClick={openModal} altText={altText} />
        </div>
        {/* Video name top left banner */}
        <div className="absolute bg-white rounded-br-2xl font-bold text-[#3057DD] pl-3 w-[2/3] shadow-medium" onClick={openModal}>
          <div className="ml-1 pl-1 pr-8 pb-1 font-fredokaSB text-xl md:text-2xl">{videoData.title}</div>
        </div>
        {/* Video background image */}
        <div className="w-full h-[75%] md:h-[80%]" onClick={openModal}>
          <img className="object-cover h-full w-full" src={videoData.staticImg} />
        </div>
        {/* Video Description */}
        <div className="absolute bottom-0 bg-white rounded-br-2xl font-bold text-[#3057DD] pl-3 h-[25%] md:h-[20%] w-full" onClick={openModal}>
          <div className="md:ml-1 md:pl-1 pr-2 md:pr-8 pb-1 pt-2 font-fredokaSB text-[.75rem] sm:text-sm md:text-lg">{videoData.description}</div>
        </div>
        <BrightcoveVideoModal isOpen={isModalOpen} onRequestClose={closeModal} accountId="1127823189" playerId="T6gsX3lo" videoId={videoData.brightCoveVideoId} triggerElement={playButtonRef} />
      </div>
    </div>
  );
};

export default VideoBlock;
