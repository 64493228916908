import { TABLET_WIDTH } from "../constants";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Printables from "./Printables";
import GPBCarousel from "./ui/GPBCarousel";
import gameLibrary from "../data/games.json";
import extGames from "../data/featured-external.json";
import { ReactComponent as StarLeft } from "../icons/star_left.svg";
import { ReactComponent as StarRight } from "../icons/star_right.svg";

const FeaturedGame = () => {
  const { width: screenWidth } = useWindowDimensions();

  const isMobile = screenWidth < TABLET_WIDTH;

  const games = gameLibrary.filter((game) => game.featured);

  // Add any in external json
  games.push(...extGames);

  return (
    <div className={`${isMobile ? "flex flex-col gap-4" : "relative"}`}>
      <div className={`${isMobile ? "order-2 mx-auto" : "absolute right-0 top-6"}`}>
        <Printables />
      </div>
      <div className="md:pt-4 relative">
        <div className="absolute top-4 ml-9 md:top-8 md:left-10 text-sm md:text-base pt-2 md:pt-2 pb-[.4rem] md:pb-[.55rem] bg-[#652FDD] flex items-center gap-2 px-4 rounded-t-xl -z-10">
          {!isMobile && <StarLeft className="w-[25px] h-[25px]" />}
          <div className="text-white font-bold whitespace-nowrap font-nunitoEB md:text-base text-sm">Featured Games</div>
          {!isMobile && <StarRight className="w-[25px] h-[25px]" />}
        </div>
      </div>
      <div className="pt-8 md:pt-9">
        <GPBCarousel games={games} autoScrollInterval={5000} />
      </div>
    </div>
  );
};

export default FeaturedGame;
