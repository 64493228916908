import AllGames from "./AllGames";
import FeaturedGame from "./FeaturedGame";
import RelatedVideos from "./RelatedVideos";

const HomeContent = () => {
  return (
    <div>
      <div className="grid grid-cols-1 gap-12 pb-12 md:px-6 md:max-w-[1300px] mx-auto">
        <FeaturedGame />
        <AllGames />
        <RelatedVideos />
      </div>
    </div>
  );
};

export default HomeContent;
