import Footer from "./Footer";
import HomeContent from "./HomeContent";
import Topbar from "./Topbar";
import ScrollToTop from "./ui/ScrollToTop";

const Home = () => {
  return (
    <div className="h-full">
      <Topbar />
      <div className="mx-6 mt-2">
        <HomeContent />
        <div className="sticky bottom-4 pb-2 z-50 flex justify-end w-full">
          <ScrollToTop className="" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
