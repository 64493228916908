import NavLink from "./ui/topbar/NavLink";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="relative bg-[#396AD3] w-full  bottom-0">
      <div className="flex flex-col items-center py-4 justify-center gap-2">
        <div className="flex items-center h-full w-full justify-center gap-12">
          <NavLink textName="About GPB" urlLink={"https://www.gpb.org/about"} />
          <NavLink textName="Privacy Policy" urlLink={"https://www.gpb.org/about/privacy-policy"} />
        </div>
        <div className="text-white font-nunitoB text-xs md:text-sm px-4 text-center">© Copyright {year} Georgia Public Broadcasting. All Rights Reserved. Georgia Public Radio® GPTV®</div>
      </div>
    </div>
  );
};

export default Footer;
