import GameBlock from "./GameBlock";

const GamesList = ({ games }) => {
  const sortedGames = games.sort((game1, game2) => game1.order - game2.order);

  return (
    <div className="bg-white flex justify-center">
      <div className="w-fit grid grid-cols-1 lg:grid-cols-2 gridLarge:grid-cols-3 gap-6 px-3 md:px-6 py-10 justify-center">{sortedGames && sortedGames.map((game, idx) => <GameBlock key={idx} game={game} />)}</div>
    </div>
  );
};

export default GamesList;
