import React from "react";
import { Carousel as CarouselFlowbite } from "flowbite-react";
import { Flowbite } from "flowbite-react";

const GPBCarousel = ({ games, autoScroll = true, autoScrollInterval = 3000 }) => {
  const getSanitizedGames = (games) => {
    return games.filter((game) => {
      if (!game.images || !game.images.banner) {
        console.error(`Selected featured game: ${game.title}, is missing banner image. Game is excluded from carousel.`);
        return false;
      }
      return true;
    });
  };

  const sanitizedGames = getSanitizedGames(games).sort((game1, game2) => game1.order - game2.order);

  // Screen reader text
  const getScreenReaderText = (game) => {
    let featuredBannerSRText = `Featured Banner: ${game.title}`;
    featuredBannerSRText += game.hasOwnProperty("featuredCallToAction") ? `; ${game.featuredCallToAction}` : "";
    return featuredBannerSRText;
  };

  // Safari detection so we can disable the carousel smooth scrolling
  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));

  const onCarouselClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="overflow-hidden md:p-5 px-3 ">
      <div className="flex border-4 border-white md:shadow-hard md:shadow-[#04A1FF] rounded-xl">
        <div className="h-[23vw] max-h-[330px] w-full focus-within:ring-2 focus-within:ring-offset-4 focus-within:ring-blue-600">
          <Flowbite
            theme={{
              theme: {
                carousel: {
                  scrollContainer: {
                    base: `flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll rounded-lg ${isSafari ? "scroll-auto" : "scroll-smooth"}`,
                  },
                },
              },
            }}
          >
            <CarouselFlowbite
              slide={autoScroll}
              slideInterval={autoScrollInterval}
              leftControl={
                <div className="!absolute top-2/4 -translate-y-2/4 rounded-full border-4 border-white bg-[#04A1FF] -left-4 md:-left-6 h-8 w-8 md:h-12 md:w-12 hover:bg-[#0779BA] transition-colors duration-150 group-focus:outline group-focus:outline-blue-600 group-focus:outline-2">
                  <i className="fas fa-caret-left text-lg md:text-3xl text-white leading-[1.5rem] md:leading-[2.5rem]" />
                </div>
              }
              rightControl={
                <div className="!absolute top-2/4 -translate-y-2/4 rounded-full border-4 border-white bg-[#04A1FF] -right-4 md:-right-6 h-8 w-8 md:h-12 md:w-12 hover:bg-[#0779BA] transition-colors duration-150 group-focus:outline group-focus:outline-blue-600 group-focus:outline-2">
                  <i className="fas fa-caret-right text-lg md:text-3xl text-white leading-[1.5rem] md:leading-[2.5rem]" />
                </div>
              }
            >
              {sanitizedGames &&
                sanitizedGames.map((game, index) => (
                  <div
                    key={index}
                    className="outline-none w-full h-full"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        onCarouselClick(game.linkUrl);
                      }
                    }}
                    onClick={() => onCarouselClick(game.linkUrl)}
                    title={getScreenReaderText(game)}
                  >
                    <img className="w-full h-full object-cover" src={game.images.banner} alt={getScreenReaderText(game)} />
                  </div>
                ))}
            </CarouselFlowbite>
          </Flowbite>
        </div>
      </div>
    </div>
  );
};

export default GPBCarousel;
