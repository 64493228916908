import { ReactComponent as PBSLogo } from "../../../icons/logo_PBS-kids.svg";
import { ReactComponent as ArrowRight } from "../../../icons/arrow_pbs.svg";

const GamesFooter = () => {
  return (
    <div className="w-full text-center text-[#3057DD] font-bold py-3 items-center font-nunitoEB text-base md:text-2xl underline hover:no-underline">
      <div className="w-fit hover:cursor-pointer mx-auto">
        <div className="flex gap-2 md:gap-4 md:px-0 px-3 justify-center items-center">
          <a
            href="https://pbskids.org/"
            target="_blank"
            onKeyDown={(e) => {
              // cover space bar for click
              if (e.key === " ") {
                e.preventDefault();
                window.open("https://pbskids.org/", "_blank");
              }
            }}
          >
            <div className="flex gap-3 items-center">
              <PBSLogo className="w-8 h-8" />
              More Games on PBS KIDS
              <ArrowRight className="w-4 h-4 md:w-6 md:h-6" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default GamesFooter;
